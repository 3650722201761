.multi-col-callout {
    padding: 0 12px 24px;
    overflow: hidden;
    max-width: none;
    @include medium {
        padding: 0 40px 40px;
    }
    @include xlarge {
        padding: 0 40px 55px;
    }
    h2.heading {
        text-align: center;
        max-width: 1584px;
        margin: 16px auto 24px;
        @include xlarge {
            text-align: left;
            padding: 0 32px;
        }
    }
    h2:empty {
        display: none;
    }
    .background-element {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        margin-top: 82px;
        @include medium {
            margin-top: 108px;
        }
        @include xlarge {
            margin-top: 144px;
        }
        &.light {
            background-color: $tan-light;
        }
        &.dark {
            background-color: $tan-dark;
        }
    }
    .row {
        max-width: 1584px;
        margin: 0 auto;
        width: 100%;
        overflow: visible;
        @include medium {
            gap: 20px;
        }
        .col {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0;
            background-color: $white;
            overflow: hidden;
            border-radius: 20px;
            margin-bottom: 36px;
            box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
            min-height: 300px;
            transition: background-color $trans-med ease, box-shadow $trans-med ease;
            @include medium {
                margin-bottom: 40px;
            }
            .col-header {
                text-align: left;
                min-height: 102px;
                padding: 8px 30px;
                display: flex;
                align-items: center;
                transition: background-color $trans-med ease;
                @include medium {
                    min-height: 138px;
                    padding: 8px 30px;
                }
                @include xlarge {
                    min-height: 148px;
                    padding: 8px 50px;
                }
                .col-svg {
                    margin-right: 24px;
                    @include xlarge {
                        margin-right: 24px;
                    }
                    svg {
                        width: 50px;
                        height: 50px;
                        @include medium {
                            max-width: 62px;
                            max-height: 62px;
                            width: 5vw;
                            height: 5vw;
                        }
                    }
                }
                .col-svg:empty {
                    display: none
                }
                h3 {
                    font-size: 1.75rem;
                    color: $white;
                    margin: 0;
                    @include medium {
                        font-size: 2rem;
                    }
                    @include xlarge {
                        font-size: 2.25rem;
                    }
                }
                .col-date {
                    color: $white;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    font-size: 1rem;
                }
            }
            .col-content {
                padding: 24px 30px 90px;
                text-align: left;
                @include medium {
                    padding: 45px 30px 78px;
                }
                @include xlarge {
                    padding: 45px 50px 78px;
                }
                p, li {
                    line-height: 1.6;
                    font-size: 1.125rem;
                }
                ol, ul {
                    padding-left: 16px;
                }
                img {
                    margin: 5px auto 15px;
                    display: block;
                }
                a {
                    color: #226893;
                    text-decoration: underline;
                }
            }
            .multi-col-link {
                position: absolute;
                bottom: 0;
                right: 4px;
                padding: 27px 32px;
                text-transform: uppercase;
                font-size: 1.125rem;
                font-weight: $font-weight-bold;
                color: $black;
                text-decoration: none;
                svg {
                    display: inline-block;
                    margin-left: 8px;
                    width: 18px;
                    height: 16px;
                    path {
                        stroke: $black;
                    }
                }
                &.button {
                    position: relative;
                    bottom: auto;
                    right: auto;
                    padding: 16px 60px 16px 30px;
                    color: $white;
                    margin: 0 auto 18px;
                    display: flex;
                    max-width: calc(100% - 60px);
                    @include medium {
                        max-width: 450px;
                    }
                    @include xlarge {
                        max-width: calc(100% - 80px);
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        text-align: left;
                    }
                    @include xxlarge {
                        max-width: calc(100% - 90px);
                    }
                    svg {
                        display: none;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &.yellow {
                .col-header {
                    background-color: $orange;
                }
            }
            &.off-white {
                .col-header {
                    background-color: $tan-light;
                    .col-svg {
                        svg {
                            path, g {
                                stroke: $black;
                            }
                        }
                    }
                    h3 {
                        color: $black;
                    }
                    .col-date {
                        color: $black;
                    }
                }
                .multi-col-link {
                    color: $black;
                    svg {
                        path {
                            stroke: $black;
                        }
                    }
                    &.button {
                        background-image: url('../images/arrow-black.svg');
                        background-color: $tan-light;
                        color: $black;
                        &:hover, &:focus {
                            background-color: $tan;
                        }
                    }
                }
            }
            &.gray-light {
                .col-header {
                    background-color: $tan;
                    .col-svg {
                        svg {
                            path, g {
                                stroke: $black;
                            }
                        }
                    }
                    h3 {
                        color: $black;
                    }
                    .col-date {
                        color: $black;
                    }
                }
                .multi-col-link {
                    color: $black;
                    svg {
                        path {
                            stroke: $black;
                        }
                    }
                    &.button {
                        background-image: url('../images/arrow-black.svg');
                        background-color: $tan;
                        color: $black;
                        &:hover, &:focus {
                            background-color: $tan-dark;
                        }
                    }
                }
            }
            &.red {
                .col-header {
                    background-color: $red;
                }
                .multi-col-link.button {
                    background-color: $red;
                    &:hover, &:focus {
                        background-color: $orange;
                    }
                }
            }
            &.blue {
                .col-header {
                    background-color: $blue;
                }
                .multi-col-link.button {
                    background-color: $blue;
                    &:hover, &:focus {
                        background-color: $blue-dark;
                    }
                }
            }
            &.green {
                .col-header {
                    background-color: $green;
                }
                .multi-col-link.button {
                    background-color: $green;
                    &:hover, &:focus {
                        background-color: $green-dark;
                    }
                }
            }
        }
        a.col {
            &.yellow {
                &:hover, &:focus {
                    .col-header {
                        background-color: $orange-dark;
                    }
                    .multi-col-link.button {
                        background-color: $red;
                    }
                }
            }
            &.off-white {
                &:hover, &:focus {
                    .col-header {
                        background-color: $tan;
                    }
                    .multi-col-link.button {
                        background-color: $tan;
                    }
                }
            }
            &.gray-light {
                &:hover, &:focus {
                    .col-header {
                        background-color: $tan-dark;
                    }
                    .multi-col-link.button {
                        background-color: $tan-dark;
                    }
                }
            }
            &.red {
                &:hover, &:focus {
                    .col-header {
                        background-color: $red-dark;
                    }
                    .multi-col-link.button {
                        background-color: $orange;
                    }
                }
            }
            &.blue {
                &:hover, &:focus {
                    .col-header {
                        background-color: $blue-dark;
                    }
                    .multi-col-link.button {
                        background-color: $blue-dark;
                    }
                }
            }
            &.green {
                &:hover, &:focus {
                    .col-header {
                        background-color: $green-dark;
                    }
                    .multi-col-link.button {
                        background-color: $green-dark;
                    }
                }
            }
            &:hover, &:focus {
                .multi-col-link {
                    text-decoration: underline;
                    &.button {
                        text-decoration: none;
                    }
                }
            }
        }
    }
    &.full-color {
        .row {
            .col {
                width: 100%;
                min-height: auto;
                display: block;
                padding: 26px 32px 68px;
                @include medium {
                    padding: 32px 56px 68px;
                }
                @include xlarge {
                    display: table-cell;
                    padding: 36px 48px 80px;
                }
                .col-header {
                    display: block;
                    min-height: auto;
                    height: auto;
                    padding: 0;
                    margin-bottom: 0;
                    background-color: transparent;
                    @include medium {
                        padding: 0;
                        min-height: auto;
                        height: auto;
                        margin-bottom: 0;
                    }
                    @include xlarge {
                        min-height: auto;
                        height: auto;
                        margin-bottom: 0;
                    }
                    .col-date {
                        margin-bottom: 36px;
                        @include medium {
                            margin-bottom: 16px;
                        }
                    }
                    h3 {
                        font-size: 1.625rem;
                        margin-bottom: 12px;
                        @include medium {
                            font-size: 1.625rem;
                        }
                        @include xlarge {
                            font-size: 1.625rem;
                        }
                    }
                    .col-svg {
                        text-align: center;
                        margin-bottom: 32px;
                        @include medium {
                            display: inline-block;
                            vertical-align: top;
                            margin-bottom: 0;
                        }
                        svg {
                            width: 86px;
                            height: auto;
                        }
                        + .full-color-heading {
                            @include medium {
                                display: inline-block;
                                max-width: calc(100% - 128px);
                                vertical-align: top;
                            }
                        }
                    }
                    + .col-content {
                        display: none;
                    }
                    .col-svg:empty {
                        display: none;
                        @include medium {
                            display: none;
                        }
                        @include xlarge {
                            display: none;
                        }
                        + .full-color-heading {
                            @include medium {
                                display: block;
                                max-width: none;
                            }
                        }
                    }
                }
                .col-content {
                    padding: 0;
                    background-color: transparent;
                    p {
                        color: $white;
                        font-size: 1.125rem;
                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        max-height: 7.5em;
                        line-height: 1.5;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        visibility: visible;
                    }
                    li, a {
                        color: $white;
                        font-size: 1.125rem;
                        line-height: 1.5;
                    }
                    ol, ul {
                        padding-left: 16px;
                    }
                }
                .multi-col-link {
                    background-image: url('../images/skinny-arrow.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 21px;
                    background-color: $white;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 57px;
                    height: 55px;
                    border-top-left-radius: 20px;
                    transition: background-color $trans-med ease;
                    font-size: 0;
                    @include medium {
                        background-size: 26px;
                        width: 71px;
                        height: 68px;
                    }
                    svg {
                        display: none;
                    }
                }
                &.yellow {
                    background-color: $orange;
                    .col-header {
                        background-color: $orange;
                    }
                }
                &.red {
                    background-color: $red;
                    .col-header {
                        background-color: $red;
                    }
                }
                &.blue {
                    background-color: $blue;
                    .col-header {
                        background-color: $blue;
                    }
                }
                &.green {
                    background-color: $green;
                    .col-header {
                        background-color: $green;
                    }
                }
                &.off-white {
                    background-color: $tan-light;
                    .col-header {
                        background-color: $tan-light;
                    }
                    .col-content {
                        p, li, a {
                            color: $black;
                        }
                    }
                }
                &.gray-light {
                    background-color: $tan;
                    .col-header {
                        background-color: $tan;
                    }
                    .col-content {
                        p, li, a {
                            color: $black;
                        }
                    }
                }
            }
            a.col {
                &.yellow {
                    &:hover, &:focus {
                        background-color: $orange-dark;
                    }
                }
                &.red {
                    &:hover, &:focus {
                        background-color: $red-dark;
                    }
                }
                &.blue {
                    &:hover, &:focus {
                        background-color: $blue-dark;
                    }
                }
                &.green {
                    &:hover, &:focus {
                        background-color: $green-dark;
                    }
                }
                &.off-white {
                    &:hover, &:focus {
                        background-color: $tan;
                    }
                }
                &.gray-light {
                    &:hover, &:focus {
                        background-color: $tan-dark;
                    }
                }
                &:hover {
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, .33);
                }
            }
        }
        &[data-col-count="2"] {
            .row {
                .col {
                    padding: 36px 32px 68px;
                    @include medium {
                        padding: 56px 64px 68px;
                    }
                    @include xlarge {
                        display: table-cell;
                        padding: 60px 64px 110px;
                    }
                    .col-header, .col-content {
                        @include xlarge {
                            padding: 0;
                        }
                    }
                    h3 {
                        font-size: 2rem;
                        @include medium {
                            font-size: 2rem;
                        }
                        @include xlarge {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }
        &[data-col-count="3"] {
            .row {
                .col {
                    .col-header, .col-content {
                        @include xlarge {
                            padding: 0;
                        }
                    }
                    .col-header {
                        h3 {
                            font-size: 1.625rem;
                            @include medium {
                                font-size: 1.625rem;
                            }
                            @include xlarge {
                                font-size: 1.625rem;
                            }
                        }
                    }
                }
            }
        }
        &[data-col-count="1"] {
            .row {
                .col {
                    width: 100%;
                    min-height: auto;
                    display: block;
                    padding: 30px 35px 95px;
                    @include medium {
                        padding: 60px 70px 75px;
                    }
                    @include xlarge {
                        display: table-cell;
                        padding: 48px 60px 85px;
                    }
                    .col-header {
                        display: block;
                        min-height: auto;
                        padding: 0;
                        margin-bottom: 20px;
                        @include medium {
                            padding: 0;
                            min-height: auto;
                            margin-bottom: 24px;
                        }
                        @include xlarge {
                            min-height: auto;
                        }
                        h3 {
                            font-size: 2.5rem;
                            margin-bottom: 20px;
                            @include medium {
                                font-size: 2.5rem;
                            }
                            @include xlarge {
                                font-size: 2.5rem;
                            }
                        }
                    }
                    .col-content {
                        padding: 0;
                        @include medium {
                            padding: 0;
                            max-width: 92%;
                        }
                        p {
                            color: $white;
                            font-size: 1.25rem;
                        }
                    }
                    &.off-white {
                        .col-content {
                            p {
                                color: $black;
                            }
                        }
                    }
                    &.gray-light {
                        .col-content {
                            p {
                                color: $black;
                            }
                        }
                    }
                }
            }
        }
    }
    &[data-col-count="1"] {
        .row {
            max-width: 986px;
        }
    }
    &[data-col-count="3"] {
        .row {
            @include medium {
                flex-direction: column;
            }
            @include large {
                flex-direction: row;
            }
            .col {
                .col-header {
                    @include xlarge {
                        padding: 8px 32px;
                    }
                    @include xxlarge {
                        padding: 8px 50px;
                    }
                    .col-svg {
                        svg {
                            @include xlarge {
                                width: 55px;
                                height: 55px;
                            }
                            @include xxlarge {
                                width: 62px;
                                height: 62px;
                            }
                        }
                    }
                    h3 {
                        @include xlarge {
                            font-size: 2rem;
                        }
                        @include xxlarge {
                            font-size: 2.25rem;
                        }
                    }
                }
                .col-content {
                    @include xlarge {
                        padding: 32px 40px 115px;
                    }
                    @include xxlarge {
                        padding: 40px 45px 125px;
                    }
                }
            }
        }
    }
    + .multi-col-callout {
        padding-bottom: 0 !important;
        margin-top: -24px;
        @include medium {
            margin-top: -40px;
        }
        @include xlarge {
            margin-top: -86px;
        }
        &:last-of-type {
            padding-bottom: 50px !important;
        }
        .background-element {
            margin-top: 0;
            @include medium {
                margin-top: 0;
            }
            @include xlarge {
                margin-top: 0;
            }
        }
        .heading {
            margin-top: 32px;
            @include medium {
                margin-top: 60px;
            }
            @include xlarge {
                margin-top: 80px;
            }
        }
        + .multi-col-callout {
            margin-top: 0;
            @include medium {
                margin-top: 0;
            }
            @include xlarge {
                margin-top: -32px;
            }
        }
    }
    + .sign-up-container {
        margin-top: -32px;
    }
}